import {initializeApp, FirebaseApp} from 'firebase/app';
import {getDatabase, ref, child, set, get, DataSnapshot, Database, DatabaseReference} from 'firebase/database';
import {
  Auth,
  getAuth,
  signInAnonymously,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {ClientInfo} from "../types/TClientInfo";


export default class FirebaseConnect {
  get dbRef(): DatabaseReference {
    return this._dbRef;
  }

  set dbRef(value: DatabaseReference) {
    this._dbRef = value;
  }
  get auth(): Auth {
    return this._auth;
  }

  set auth(value: Auth) {
    this._auth = value;
  }



  static instance: FirebaseConnect;
  private readonly _firebaseConfig = {
    apiKey: "AIzaSyADZD-FGEzr9F50NqxmYu_VAsxP2Ll0sew",
    authDomain: "aposcambot.firebaseapp.com",
    databaseURL: "https://aposcambot-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "aposcambot",
    storageBucket: "aposcambot.appspot.com",
    messagingSenderId: "52627719243",
    appId: "1:52627719243:web:0f89b900733b8860c479ce"
  };

  private _app: FirebaseApp = initializeApp(this._firebaseConfig);
  private _database: Database = getDatabase(this._app);
  private _dbRef : DatabaseReference = ref(this._database);
  private _auth : Auth = getAuth();

  constructor() {
    // console.log('yo firebase app = ', this._app);
    // console.log('firebase db = ', this._database);
    //
    signInAnonymously(this.auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    if (FirebaseConnect.instance) {
      return FirebaseConnect.instance;
    }
    FirebaseConnect.instance = this;
  }

  public loadFirebaseData = (): Promise<boolean> => {

    console.log('load user data... uid -> ', this.auth.currentUser?.uid);

    return new Promise((resolve, reject) => {

      get(child(this.dbRef, `/users/${this.auth.currentUser?.uid}/`)).then((snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          // this.userData = snapshot.val();
          // console.log("this.userData = ", this.userData.users);
          // this.usersData = this.userData.users;
          resolve(!!this.auth.currentUser);
        } else {
          console.log("No data available");
          reject();
        }
      }).catch((error: any) => {
        console.error('yomama', error);
        reject();
      });

    });
  }


  public logoutFirebase = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      signOut(this.auth).then(() => {
        // Sign-out successful.

        resolve(!!this.auth)

      }).catch(() => {
        // An error happened.
        reject();
      });
    });
  }

  public saveFirebaseData = (clientInfo : ClientInfo, sessionID : string): Promise<void> => {
    // return;
    return new Promise((resolve, reject) => {

      // console.log('this.dbRef = ' + this.dbRef);
      // console.log('trying to save data to /users/' + this.auth.currentUser.uid);

      set(child(this.dbRef, `/users/${clientInfo.uuid}/${sessionID}`), {
        clientInfo,
      }).then(() => {
        resolve();
      })
        .catch((error: any) => {
          console.error('yomama', error);
          reject();
        });

    });
  }

}
