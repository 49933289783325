import ScanbotSDK from 'scanbot-web-sdk'
import {BarcodeResult} from "scanbot-web-sdk/@types";
import {Html5QrcodeScanner} from "html5-qrcode";
// import {ready, scan} from 'qr-scanner-wechat'
import FirebaseConnect from "./FirebaseConnect";
import {ClientInfo} from "../types/TClientInfo"

const overlay = document.querySelector('.overlay')!;
const close = document.querySelector('.overlay .close')!;

// Classes

declare global {
  interface Window {
    cv: any
  }
}

let Instance: App;

export default class App {
  private html5QrcodeScanner: Html5QrcodeScanner;

  // @ts-ignore
  private interval: number = -1;
  private firebaseConnect: FirebaseConnect;
  private clientInfo: ClientInfo;
  private proxyclientInfo: ClientInfo;
  private scanAreas: NodeListOf<HTMLDivElement>;
  private sessionId: string;
  private QRCodeDetector: any;

  constructor() {
    Instance = this;

    // @ts-ignore
    console.log('cv = ', window.cv);

    const script : HTMLScriptElement = document.createElement('script');
    script.onload = () => {
      console.log('opencv loaded - cv = ', window.cv);
      window.cv.then((cv: any) => {
        window.cv = cv;
        this.QRCodeDetector = new cv.QRCodeDetector();
      })
    };
    script.src = './opencv.js';

    document.head.appendChild(script);

    this.clientInfo = {
      uuid: (localStorage.getItem('scambotUUID')) !== null ? localStorage.getItem('scambotUUID')! : this.createUUID()
    };

    // @ts-ignore
    this.sessionId = new Date().getTime().toString();

    this.firebaseConnect = new FirebaseConnect()
    this.scanAreas = document.querySelectorAll('.scan-area')!;


    this.html5QrcodeScanner = new Html5QrcodeScanner(
      "html5qrcodescanner",
      {fps: 30, qrbox: {width: 512, height: 512}},
      /* verbose= */ false);

    this.registerListeners();
    this.fillGeneralClientInfo();

    this.proxyclientInfo = new Proxy(this.clientInfo, {
      set: (target, key: string | symbol, value): boolean => {
        // @ts-ignore
        console.log(`Setting info ${key} to ${value} - updating: `, target);
        Reflect.set(target, key, value);
        if (key === 'scanbotResult' || key === 'html5qrResult' || key === 'opencvResult')
        {
          if(value !== true) return true;
          key = (key as string).replace('Result', '');

          console.log(document.querySelector('.button-wrap[data-type="' + key.toString() + '"]'));
          document.querySelector('.button-wrap[data-type="' + key.toString() + '"]')!.setAttribute('data-isvalid', '');
        }

        this.firebaseConnect.saveFirebaseData(this.clientInfo, this.sessionId);
        return true;
      }
    })

  }

  private createUUID(): string {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  private fillGeneralClientInfo = async () => {
    if (navigator.userAgentData) {
      let values: UADataValues | undefined = await navigator.userAgentData?.getHighEntropyValues(['model']);
      this.proxyclientInfo.model = values.model;
      this.proxyclientInfo.platform = values.platform;
    }
    this.proxyclientInfo.userAgentString = navigator.userAgent;

    if (localStorage.getItem('scambotUUID') === null) localStorage.setItem('scambotUUID', this.createUUID());

    console.log(this.clientInfo);
  }

  private onClick = (e: Event) => {
    document.querySelectorAll('#scanbot, #html5qrcodescanner, #opencv').forEach((el) => {
      // el.innerHTML = '';
      (el as HTMLElement).style.opacity = "0";
      (el as HTMLElement).classList.remove("active");
    });

    overlay.classList.add('active');

    if ((e.currentTarget as HTMLElement).classList.contains('scanbot')) {
      console.log('init scanbot scanner...');
      (document.getElementById('scanbot') as HTMLElement).innerHTML = '';
      this.initScanbot();

      (document.getElementById('scanbot') as HTMLElement).style.opacity = '1';
      (document.getElementById('scanbot') as HTMLElement).classList.add('active');

    } else if ((e.currentTarget as HTMLElement).classList.contains('html5qr')) {
      console.log('init html5qrcode scanner...');
      (document.getElementById('html5qrcodescanner') as HTMLElement).innerHTML = '';

      this.html5QrcodeScanner.render(this.onHTML5ScanSuccess, this.onHTML5ScanFailure);
      (document.getElementById('html5qrcodescanner') as HTMLElement).style.opacity = '1';
      (document.getElementById('html5qrcodescanner') as HTMLElement).classList.add('active');

    } else if ((e.currentTarget as HTMLElement).classList.contains('opencv')) {
      console.log('init opencv scanner...');
      this.initOpenCV();

      (document.getElementById('opencv') as HTMLElement).style.opacity = '1';
      (document.getElementById('opencv') as HTMLElement).classList.add('active');
    }

    console.log(this.clientInfo);
  }

  private registerListeners = () => {
    document.querySelectorAll('.btn').forEach((btn) => {
      btn.addEventListener('click', this.onClick);
    })

    close.addEventListener('click', () => {
      overlay.classList.remove('active');
      this.html5QrcodeScanner.clear();
      clearInterval(this.interval);

      this.scanAreas.forEach((area) => {
        if (area.classList.contains('active')) {
          if (area.id === 'opencv') {
            if (!!Instance.clientInfo.opencvResult) return;
            Instance.proxyclientInfo.opencvResult = false;
          } else if (area.id === 'scanbot') {
            if (!!Instance.clientInfo.scanbotResult) return;
            Instance.proxyclientInfo.scanbotResult = false;
          } else if (area.id === 'html5qrcodescanner') {
            if (!!Instance.clientInfo.html5qrResult) return;
            Instance.proxyclientInfo.html5qrResult = false;
          }
        }
      })

      console.log('syncing clientInfo with firebase... ', this.clientInfo);
    })
  }

  private async initOpenCV() {

    const supported = navigator.mediaDevices.getSupportedConstraints().facingMode;
    let mediaDeviceConfig: MediaStreamConstraints = {
      audio: false,
      video: {
        width: 512,
        height: 512,
      }
    };
    if (supported && mediaDeviceConfig.video) mediaDeviceConfig.video.facingMode = 'environment';

    console.log(mediaDeviceConfig);

    const stream = await navigator.mediaDevices.getUserMedia(mediaDeviceConfig);

    console.log('sztream = ', stream);

    const video: HTMLVideoElement = document.getElementById('myvideo') as HTMLVideoElement;
    video.srcObject = stream
    video.play()

    const canvas : HTMLCanvasElement = document.createElement('canvas');// document.getElementById('mycanvas') as HTMLCanvasElement; // document.createElement('canvas')
    canvas.width = 512;
    canvas.height = 512;
    // document.getElementById('opencv')!.appendChild(canvas);

    async function scanFrame() {
      // console.log('scanning frame...');



      const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      ctx.putImageData(Instance.preprocessImage(canvas), 0, 0);

      // @ts-ignore
      let mat = window.cv.imread(canvas);

      // const result = await scan(canvas)
      const result = await Instance.QRCodeDetector.detectAndDecode(mat)



      if (!!result) {
        console.log('result = ', result);
        alert(result)
        Instance.proxyclientInfo.opencvResult = true;
        console.log(Instance.clientInfo);
        clearInterval(Instance.interval);
        video.srcObject = null;
      }
    }

    this.interval = window.setInterval(scanFrame, 20) // scan one frame every 100ms
  }

  private async initScanbot() {
    const myLicenseKey = "";
    const scanbotSDK = await ScanbotSDK.initialize({
                                                     licenseKey: myLicenseKey
                                                   });
    //
    console.log('scanbotSDK = ', scanbotSDK);

    const configuration = {
      //  The `id` of the containing HTML element where the Barcode Scanner will be initialized.
      containerId: 'scanbot',
      onBarcodesDetected: (result: BarcodeResult) => {
        // If you're happy with the result, dispose the scanner right away
        scanner.dispose();
        // Otherwise the scanner will continue scanning and delivering results
        const format = result.barcodes[0].format // The barcode's symbology
        const text = result.barcodes[0].text // The text value of the barcode
        // ...

        // this.firebaseConnect.saveEntry({
        //   passed : true,
        //   // device :
        //                                });

        alert('Barcode detected - text = ' + text);
        console.log('barcode detected - format = ', format);
        console.log('barcode detected - text = ', text);

        Instance.proxyclientInfo.scanbotResult = true;
        console.log(Instance.clientInfo);
      },
      onError: () => {
        console.log('error finding barcode');
        Instance.proxyclientInfo.scanbotResult = false;
        console.log(Instance.clientInfo);
      },
      style: {
        window: {
          aspectRatio: 1,
          widthProportion: 0.8,
          borderColor: "white",
          width: "100%",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        },
        // text: {
        //   color: string = "white";
        //   size: string = "0.9em";
        //   weight: number = 300;
        // },
        // hint: "Please align the code in the frame above to scan it",
        // backgroundColor: string = "rgba(0, 0, 0, 0.7)";
      }
    }

    const scanner = await scanbotSDK.createBarcodeScanner(configuration);
  }

  private onHTML5ScanSuccess(decodedText: any, decodedResult: any) {
    // handle the scanned code as you like, for example:
    console.log(`Code matched = ${decodedText}`, decodedResult);

    alert('Barcode detected - text = ' + decodedText);

    Instance.proxyclientInfo.html5qrResult = true;
    console.log(Instance.clientInfo);
  }

  private onHTML5ScanFailure(error: any) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    // console.warn(`Code scan error = ${error}`);

    // Instance.proxyclientInfo.html5qrResult = false;
    // console.log(Instance.clientInfo);
  }

//   image processing

  private preprocessImage(canvas : HTMLCanvasElement) : ImageData {
    const processedImageData = canvas.getContext('2d')!.getImageData(0,0,canvas.width, canvas.height);
    this.thresholdFilter(processedImageData.data, 0.5);
    return processedImageData;
  }

  // from https://github.com/processing/p5.js/blob/main/src/image/filters.js
  private thresholdFilter(pixels:any, level : number) {
    if (level === undefined) {
      level = 0.5;
    }
    const thresh = Math.floor(level * 255);
    for (let i = 0; i < pixels.length; i += 4) {
      const r = pixels[i];
      const g = pixels[i + 1];
      const b = pixels[i + 2];
      const gray = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      let val;
      if (gray >= thresh) {
        val = 255;
      } else {
        val = 0;
      }
      pixels[i] = pixels[i + 1] = pixels[i + 2] = val;
    }
  }
}
